<template>
  <b-row>
    <b-col cols="12" lg="10" xl="8" class="mx-auto" style="max-width:1200px">
      <b-tabs content-class="mt-3" style="padding-top:5px">
        <b-tab title="Equipment">
          <b-row style="padding-bottom:4px">
            <b-col>
              <b-button size="sm" @click="newMateriel()" variant="success">Create New Equipment</b-button>
            </b-col>
            <b-col>
              <b-form-checkbox class="text-right" v-model="load_archived_equipment" @change="loadEquipment">Include Archived</b-form-checkbox>
              <b-form-input size="sm" placeholder="Filter text" @change="loadEquipment(1)" v-model="filter_text"></b-form-input>
            </b-col>
          </b-row>
          <b-card body-class="materiel-card-body" v-for="equip in equipment" :key="equip.id" >
            <b-row>
              <b-col cols="6">
                <b-card-title><b>{{equip.manufacturer}}</b> {{equip.model}}</b-card-title>
                <b-card-sub-title>{{equip.description}}</b-card-sub-title>
              </b-col>
              <b-col cols="6" style="display:flex; flex-direction: column">
                <b-button-group style="align-self: flex-end">
                  <b-button variant="warning"
                            :to="`/edit-materiel/${equip.id}`"
                            title="Edit Item">
                    <b-icon-pencil-square></b-icon-pencil-square>
                  </b-button>
                  <b-button variant="danger"
                            v-b-modal="'delete-confirmation-'+equip.id"
                            title="Delete Item">
                    <b-icon-trash></b-icon-trash>
                  </b-button>
                  <b-modal :id="'delete-confirmation-'+equip.id" ok-title="Delete" ok-variant='danger'
                           title="Delete Confirmation" @ok="deleteMateriel(equip.id)">
                    Confirm that you wish to delete #{{equip.id}} "{{equip.manufacturer}} {{equip.model}}"?
                    <hr>
                    <p><b>Only delete entries if they were created in error. Otherwise, please just mark the entry
                      as archived using the "Edit" interface.</b></p>
                  </b-modal>
                </b-button-group>

              </b-col>

            </b-row>
            <b-card-body style="padding-top: 5px;padding-bottom: 5px">
              <b-row>
              <b-col cols="12" md="4">
                <b-row>
                  <div v-if="equip.photo" style="position: relative">
                    <b-img fluid thumbnail center :src="equip.photo.inline_url"
                           :style="{'max-height': '400px', 'filter': (equip.archived ? 'grayscale(100%)' : undefined)}"
                    ></b-img>
                    <div v-if="equip.archived"
                         style="position: absolute; right:10px; bottom: 10px; color:#FFFFFF; background-color:#66666699; font-size: large"
                    >ARCHIVED</div>
                  </div>

                  <div v-else style="position: relative">
                    <b-icon-card-image style="width:100%; height:200px"></b-icon-card-image>
                    <div v-if="equip.archived"
                         style="position: absolute; right:10px; bottom: 10px; color:#FFFFFF; background-color:#66666699; font-size: large"
                    >ARCHIVED</div>
                  </div>
                </b-row>
                <b-row>
                  <b-list-group style="width:100%; padding:2px; font-size:small" flush>
<!--                    <b-list-group-item style="padding:2px">Created {{equip.date_created}}</b-list-group-item>-->
                    <b-list-group-item style="padding:2px">Last edit {{equip.date_last_edited}}</b-list-group-item>
                  </b-list-group>
                </b-row>
              </b-col>
              <b-col cols="12" md="8" style="display:flex; flex-direction: column">
                <table class="table table-sm" style="max-width: 100%">
                  <tr v-if="equip.serial_number"><td>Serial Number</td> <td>{{equip.serial_number}}</td></tr>
                  <tr v-if="equip.location"><td>Location</td> <td>{{equip.location}}</td></tr>
                  <tr v-if="equip.url"><td>Website</td>
                    <td ><a :href="equip.url">{{formatFilename(equip.url)}}</a></td>
                  </tr>
                  <tr v-if="equip.date_received"><td>Date Received</td> <td>{{equip.date_received}}</td></tr>
                  <tr v-if="equip.archived"><td>Date Archived</td> <td>{{equip.date_archived}}</td></tr>
                  <tr v-if="equip.owner"><td>Owner</td> <td>{{equip.owner}}</td></tr>
                  <tr v-if="equip.inventory_tag_id"><td>Inventory Tag</td> <td>{{equip.inventory_tag_id}}</td></tr>
                  <tr v-if="equip.datasheet"><td>Datasheet</td>
                    <td><a :href="equip.datasheet.url">{{formatFilename(equip.datasheet.original_filename)}}</a></td>
                  </tr>
                  <tr v-if="equip.manual"><td>Manual</td>
                    <td><a :href="equip.manual.url">{{formatFilename(equip.manual.original_filename)}}</a></td>
                  </tr>
                  <tr v-if="equip.notes"><td>Notes</td> <td>{{equip.notes}}</td></tr>
                </table>
              </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <b-pagination
              v-model="current_page_equipment"
              :total-rows="n_equipment"
              :per-page="per_page_equipment"
              align="center"
              v-on:change="loadEquipment"
              first-number last-number
          >
          </b-pagination>
        </b-tab>
        <b-tab title="Supplies">
          <b-icon-cone-striped></b-icon-cone-striped> Under Construction <b-icon-cone-striped></b-icon-cone-striped>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>

</template>

<script>
import http from "../http-common"
const _ = require('lodash');
export default {
  name: "MaterielHome",
  data: function () {
    return {
      equipment: [],
      n_equipment: 0,
      current_page_equipment: 1,
      per_page_equipment: 30,
      load_archived_equipment: false,
      filter_text: "",
    };
  },
  methods: {
    loadEquipment(page) {
      let loader = this.$loading.show();
      if ((page||null) === null) {
        this.current_page_equipment = 1;
      } else {
        this.current_page_equipment = page;
      }
      http.get('/materiel/equipment', {
        params: {
          offset: (this.current_page_equipment - 1) * this.per_page_equipment,
          limit: this.per_page_equipment,
          include_archived: this.load_archived_equipment,
          filter_text: this.filter_text,
        }})
      .then((resp) => {
        this.equipment = resp.data.materiels;
        this.n_equipment = resp.data.n_materiels;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        loader.hide();
      })
    },
    newMateriel() {
      http.post('/materiel')
          .then((resp) => {
            this.$router.push(`/edit-materiel/${resp.data.id}`)
      })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
    },
    deleteMateriel(materiel_id) {
      http.delete(`/materiel/${materiel_id}`)
          .then(() => {
            this.loadEquipment();
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
    },
    formatFilename(fname) {
      return _.truncate(fname);
    },
  },
  mounted() {
    this.loadEquipment();
  },
}
</script>

<style>
.materiel-card-body {
  padding-bottom: 0;
  padding-top: 5px
}
</style>